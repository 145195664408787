<template>
    <div>
        <h1>Document Types</h1>
        <div id="docs">
            <md-card v-for="(item,index) in documentTypes" v-bind:key="index">
                <md-card-header>
                    <div class="md-title">{{item.name}}</div>
                </md-card-header>
                <md-card-content>
                    {{item.instructions}}

                    <a class="download-icon" target="_blank" v-if="item.code === 'SELF_ID_FORM'" href="/docs/self_id_form.pdf">
                        <img alt="download pdf" src="/pdf_icon.png" />  download
                    </a>

                    <a class="download-icon" target="_blank" v-if="item.code === 'SELF_ID_FORM'" href="/docs/self_id_form.doc">
                        <img alt="download doc" src="/word_icon.gif" />  download
                    </a>

                    <a class="download-icon" target="_blank" v-if="item.code === 'ENG_FLUENCY_CERT'" href="/docs/english_fluency_certification.pdf">
                        <img alt="download doc" src="/pdf_icon.png" />  download
                    </a>

                    <a class="download-icon" target="_blank" v-if="item.code === 'ENG_FLUENCY_CERT'" href="/docs/english_fluency_certification.doc">
                        <img alt="download pdf" src="/word_icon.gif" />  download
                    </a>

                    <a class="download-link" target="_blank" v-if="item.code === 'DRAFT_OFFER_LET'" href="https://www.sas.upenn.edu/academics/resources-faculty/resources-for-administrators">
                      <span>Offer Letter Templates</span><i class="md-icon md-icon-font inline-header-icon md-theme-default">open_in_new</i>
                    </a>

                    <a class="download-link" target="_blank" v-if="item.code === 'DRAFT_OFFER_LET'" href="https://apps.sas.upenn.edu/faculty/fido">
                      <span>Offer Letter Generators</span><i class="md-icon md-icon-font inline-header-icon md-theme-default">open_in_new</i>
                    </a>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
export default {
  name: 'DocumentTypes',
  data: () => ({
  }),
  computed: {
    documentTypes() {
      return this.$store.getters['title/documentTypes'];
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .md-card {
        margin: 10px;
        flex-basis: 400px;
        flex-grow: 1;
        flex-shrink: 1;
        justify-content: space-around;
        justify-items: space-around;
    }
    #docs {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }

    a.download-icon img {
        width: 30px
    }

    a.download-icon {
        display: block;
    }

    a.download-link, a.download-link:hover {
      display: block;
      text-decoration: none;
    }
    a.download-link:hover .md-icon.md-theme-default.md-icon-font {
      color: #0000FF;
      text-decoration: none;
    }
    a.download-link:hover span {
      color: #0000FF;
      text-decoration: underline;
    }

</style>
